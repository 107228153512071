import { useTable, useSortBy, useRowState } from 'react-table';
import PropTypes from 'prop-types';
import { SortIcon } from '../Icon/Icon';

import styles from './Table.module.scss';
import LocalErrorBoundary from '../ErrorBoundary/LocalErrorBoundary';

const SortIcons = ({ sorted, dir }) => (
    <span className={styles.SortingIcons}>
        <SortIcon />
        {sorted && <span data-direction={dir} /* white box to hide the inactive direction */ />}
    </span>
);

const Table = ({ data = [], columns, defaultSort, emptyState, rowAction }) => {
    const toggleEditRow = (row) => {
        row.setState((previousState) => ({
            ...previousState,
            isEditing: !previousState.isEditing,
        }));
    };

    const editCellContent = (row, cell, value) => {
        row.setState((previousState) => ({
            ...previousState,
            changes: {
                ...previousState.changes,
                [cell]: value,
            },
        }));
    };

    const resetChanges = (row) => {
        row.setState((previousState) => ({
            ...previousState,
            changes: {},
        }));
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            autoResetSortBy: false,
            toggleEditRow,
            editCellContent,
            resetChanges,
            initialRowStateAccessor: () => ({
                changes: {},
            }),
            initialState: {
                ...(defaultSort && { sortBy: defaultSort }),
            },
        },
        useSortBy,
        useRowState
    );

    return (
        <table {...getTableProps()} className={styles.Table} data-testid='Table'>
            <thead>
                {headerGroups.map((headerGroup) => {
                    const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

                    return (
                        <tr key={key} {...headerGroupProps}>
                            {headerGroup.headers.map((column) => {
                                const headerProps = column.getHeaderProps(
                                    column.sortable ? column.getSortByToggleProps() : undefined
                                );
                                const { key, ...restHeaderProps } = headerProps;
                                return (
                                    <th key={key} {...restHeaderProps}>
                                        <div className={styles.HeaderCell}>
                                            {column.sortable && (
                                                <SortIcons
                                                    sorted={column.isSorted}
                                                    dir={column.isSortedDesc ? 'DESC' : 'ASC'}
                                                />
                                            )}
                                            {column.render('Header')}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    );
                })}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? (
                    rows.map((row) => {
                        prepareRow(row);
                        const { key, ...rowProps } = row.getRowProps();
                        return (
                            <tr
                                key={key}
                                {...rowProps}
                                data-testid='TableRow'
                                onClick={
                                    rowAction
                                        ? () => {
                                              rowAction(row);
                                          }
                                        : null
                                }
                            >
                                <LocalErrorBoundary className={styles.RowError} row>
                                    {row.cells.map((cell, index) => {
                                        const { key, ...cellsProps } = cell.getCellProps();
                                        return (
                                            <td
                                                key={key}
                                                className={cell.column.cellClass}
                                                {...cellsProps}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </LocalErrorBoundary>
                            </tr>
                        );
                    })
                ) : (
                    <tr data-testid='TableRow'>
                        <td colSpan={columns.length} align={'center'}>
                            {emptyState}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            accessor: PropTypes.string.isRequired,
            cellClass: PropTypes.string,
            sortable: PropTypes.bool,
            Cell: PropTypes.func,
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    defaultSort: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            desc: PropTypes.bool.isRequired,
        })
    ),
    emptyState: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    rowAction: PropTypes.func,
};

export default Table;
