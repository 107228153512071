import Table from '../Table/Table';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import emptyStateImage from './empty-state.png';
import styles from './DownloadsByCategory.module.scss';
import Input from '../Form/Input/Input';
import Tag from '../Tag/Tag';
import useDateFormatter from '../../hooks/useDateFormatter';
import { DownloadsIcon } from '../Icon/Icon';

const DownloadsByCategory = (props) => {
    const { toDateString } = useDateFormatter();
    const { t } = useTranslation();
    const [search, setSearch] = useState('');

    const filteredDownloads = useMemo(() => {
        return props.downloads.filter((download) => {
            if (download.name && search) {
                return download.name.toLowerCase().includes(search.toLowerCase());
            } else {
                return true;
            }
        });
    }, [search, props.downloads]);

    const columns = [
        {
            Header: t('downloadsByCategory.columnName'),
            accessor: 'name',
            sortable: true,
            cellClass: `${styles.ThWidth}`,
            Cell: ({ row }) => {
                return row.original.name;
            },
        },
        {
            Header: t('downloadsByCategory.columnCategory'),
            accessor: 'main_category.name',
            sortable: false,
            cellClass: `${styles.ThWidth}`,
            Cell: ({ row }) => {
                const { main_category } = row.original;
                return main_category?.name ? (
                    <Tag className={styles.CategoryTag} text={main_category.name} />
                ) : null;
            },
        },
        {
            Header: t('downloadsByCategory.columnDate'),
            accessor: 'updated_at',
            sortable: true,
            cellClass: `${styles.ThWidth}`,
            Cell: ({ row }) => toDateString(row.original.updated_at),
        },
        {
            Header: (
                <Input
                    type='text'
                    value={search}
                    onChange={setSearch}
                    placeholder={t('downloadsByCategory.search')}
                    icon={'search'}
                    className={styles.Search}
                />
            ),
            accessor: 'id',
            sortable: false,
            cellClass: `${styles.ThWidth}`,
            Cell: ({ row }) => (
                <div className={styles.Last}>
                    <a href={row.original.url} className={styles.DownloadLink}>
                        <DownloadsIcon className={styles.DownloadIcon} />
                    </a>
                </div>
            ),
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                data={filteredDownloads}
                defaultSort={[
                    {
                        id: 'name',
                        desc: false,
                    },
                ]}
                emptyState={
                    <div>
                        <img src={emptyStateImage} alt={''} />
                        <div className={styles.EmptyHeadline}>
                            {t('downloadsByCategory.emptyStateHeadline')}
                        </div>
                        <div className={styles.EmptySubHeadline}>
                            {t('downloadsByCategory.emptyStateSubHeadline')}
                        </div>
                    </div>
                }
            />
        </div>
    );
};
export default DownloadsByCategory;
