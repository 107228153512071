import PropTypes from 'prop-types';
import { useMemo, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useTransforms } from './useDownloads';
import { DownloadsIcon } from '../Icon/Icon';
import Table from '../Table/Table';
import Tag from '../Tag/Tag';
import FilterButtons from './FilterButtons';
import useDateFormatter from '../../hooks/useDateFormatter';
import styles from './Downloads.module.scss';

function filterDuplicatesById({ id: idToCheck }, index, allItems) {
    return allItems.findIndex(({ id }) => id === idToCheck) === index;
}

function getCategoriesFromDownloads(downloads) {
    return downloads
        .reduce((allCategories, { categories }) => {
            return allCategories.concat(
                categories.map((category) => ({
                    id: category.id,
                    text: category.name,
                }))
            );
        }, [])
        .filter(filterDuplicatesById);
}

const Downloads = ({ downloads = [] }) => {
    const listRef = useRef();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { bytesToString } = useTransforms();
    const { toDateString } = useDateFormatter();
    const availableCategories = useMemo(() => getCategoriesFromDownloads(downloads), [downloads]);

    const [selectedCategories, setSelectedCategories] = useState(
        availableCategories.map(({ id }) => id)
    );

    const filteredDownloads = useMemo(
        () =>
            downloads.filter(
                ({ categories }) =>
                    selectedCategories.length === 0 ||
                    categories.some(({ id }) => selectedCategories.includes(id))
            ),
        [selectedCategories, downloads]
    );

    useEffect(() => {
        if (searchParams.get('category')) {
            console.log('setSelectedCategories', [searchParams.get('category')]);
            setSelectedCategories([searchParams.get('category')]);
        }
    }, [searchParams]);

    const columns = useMemo(
        () => [
            {
                Header: t('downloads.columnName'),
                accessor: 'name',
                sortable: true,
            },
            {
                Header: t('downloads.columnCategory'),
                accessor: 'mainCategory',
                sortable: true,
                Cell: ({ row }) => {
                    const { mainCategory } = row.original;
                    return mainCategory ? (
                        <Tag className={styles.CategoryTag} text={mainCategory.name} />
                    ) : null;
                },
                sortType: (
                    { original: { mainCategory: category1 } },
                    { original: { mainCategory: category2 } }
                ) => {
                    const name1 = category1?.name || '';
                    const name2 = category2?.name || '';
                    return name1.localeCompare(name2);
                },
            },
            {
                Header: t('downloads.columnDate'),
                accessor: 'updatedAt',
                sortable: true,
                Cell: ({ row }) =>
                    row.original.type === 'booklet'
                        ? t('downloads.dateForBooklet')
                        : toDateString(row.original.updatedAt),
            },
            {
                Header: t('downloads.columnFileSize'),
                accessor: 'fileSize',
                sortable: true,
                Cell: ({ row }) =>
                    row.original.type === 'booklet'
                        ? row.original.fileSize
                        : bytesToString(row.original.fileSize),
            },
            {
                Header: t('downloads.columnFileType'),
                sortable: true,
                accessor: 'fileExtension',
                Cell: ({ row }) =>
                    row.original.type === 'booklet'
                        ? row.original.fileType === 'xml'
                            ? t('downloads.xml')
                            : t('downloads.pdf')
                        : row.original.fileExtension,
            },
            {
                Header: t('downloads.columnAction'),
                accessor: 'url',
                Cell: ({ row }) => {
                    return (
                        <a href={row.original.url} className={styles.DownloadLink}>
                            <DownloadsIcon className={styles.DownloadIcon} />
                        </a>
                    );
                },
            },
        ],
        [t, bytesToString, toDateString]
    );

    const defaultSort = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        []
    );

    useDocumentTitle(t('navigation.allDownloadsTitle'), listRef);

    return (
        <div ref={listRef} className={styles.Downloads} data-testid='Downloads'>
            <h1 className={styles.PageTitle}>{t('navigation.allDownloadsTitle')}</h1>
            <FilterButtons
                options={availableCategories}
                value={selectedCategories}
                onChange={(categories) => setSelectedCategories(categories)}
            />
            <div className={styles.TableContainer}>
                <Table columns={columns} data={filteredDownloads} defaultSort={defaultSort} />
            </div>
        </div>
    );
};

Downloads.propTypes = {
    downloads: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            fileName: PropTypes.string,
            fileSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            fileType: PropTypes.string.isRequired,
            mainCategory: PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }),
            categories: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                })
            ),
        })
    ),
};

export default Downloads;
